import { render, staticRenderFns } from "./NtttsEventRegistration.vue?vue&type=template&id=1858770a&"
import script from "./NtttsEventRegistration.vue?vue&type=script&lang=js&"
export * from "./NtttsEventRegistration.vue?vue&type=script&lang=js&"
import style0 from "./NtttsEventRegistration.vue?vue&type=style&index=0&id=1858770a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports